<template lang="pug">
v-app.layout-default
  app-nav-drawer(v-model="navDrawer")
  app-header(@nav:click="openNav")
  v-main
    slot
  app-footer(v-if="!noFooter")
</template>

<script>
  import AppNavDrawer from '@/components/app/AppNavDrawer';
  import AppHeader from '@/components/app/AppHeader';
  import AppFooter from '@/components/app/AppFooter';
  import GetAppBanner from '@/components/app/GetAppBanner.vue';

  export default {
    name: 'layout-default',
    components: {
      AppNavDrawer,
      AppHeader,
      AppFooter,
      GetAppBanner,
    },

    props: {
      noFooter: { type: Boolean, default: false },
      showBanner: { type: Boolean, default: false },
    },

    data() {
      return {
        navDrawer: false,
      };
    },

    methods: {
      openNav() {
        this.navDrawer = true;
      },
    },
  };
</script>

<style lang="scss">
  .v-application.layout-default {
    background-color: c('gray5', 'light') !important;
  }
</style>
